import React, { ReactNode, useContext, useState } from 'react';
import { TWidgetSchema } from '../types';

interface ISchemaContext {
  schema: TWidgetSchema;
  setSchema: React.Dispatch<React.SetStateAction<TWidgetSchema>>;
}

interface ISchemaProviderProps {
  initialSchema: TWidgetSchema;
  children: ReactNode;
}

const SchemaContext = React.createContext<ISchemaContext>({ schema: null!, setSchema: () => {} });

export const useSchema = () => useContext(SchemaContext)

export const SchemaProvider = ({ initialSchema, children }: ISchemaProviderProps) => {
  const [schema, setSchema] = useState(initialSchema)

  return (
    <SchemaContext.Provider value={{ schema, setSchema }}>
      {children}
    </SchemaContext.Provider>
  )
}
