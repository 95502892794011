/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useLocale } from '../../hooks/useLocale';
import { useObserver } from '../../hooks/useObserver';
import { useFilters } from '../../hooks/useFilters';
import { TEnumFilter } from '../cells/TableHeadCell';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { name } from '../../../package.json'

export type TEnumFilterRow = {
  title: string
  key: string
}

export const EnumFilter = ({ columnKey, sortingField, localization, closeFilterPanel }: TEnumFilter) => {
  const { $t } = useLocale()
  const { filters, setFilters } = useFilters()
  const { eventBus, eventBusID } = useObserver()
  const [filterItems, setFilterItems] = useState<TEnumFilterRow[]>([])
  const [selectedItems, setSelectedItems] = useState<TEnumFilterRow[]>([])

  const applyFilter = (value: TEnumFilterRow[]) => {
    setSelectedItems(value)
  }

  const cleanInputField = () => {
    setSelectedItems([])
  }

  const resetFilter = (e: SyntheticEvent) => {
    cleanInputField()
    submitFormFilter(e, '')
  }

  const submitFormFilter = (e: SyntheticEvent, emptyValue?: string) => {
    e.preventDefault()
    eventBusID && eventBus.notify(eventBusID, 'filterUpdate', {
      publisher: name,
      occurrenceTime: Date.now(),
      data: {
        payload: {
          [columnKey]: typeof emptyValue === 'string' ? emptyValue : selectedItems.map(({ key }) => key)
        },
        sortingField
      }
    })

    setFilters((prev) => {
      const newFilters = new Map(prev?.entries() || [])
      typeof emptyValue === 'string'
        ? newFilters.delete(columnKey)
        : newFilters.set(columnKey, selectedItems.map(({ key }) => key))
      return newFilters
    })

    closeFilterPanel(e)
  }

  useEffect(() => {
    const filterValue = filters?.get(columnKey)
    if (
      localization
      && Array.isArray(filterValue)
      && filterValue.length
    ) {
      const items = $t<Record<string, string>>(localization.path) as Record<string, string>
      setSelectedItems(filterValue.reduce<TEnumFilterRow[]>((acc, key) => {
        acc.push({
          key: key as string,
          title: items[key as string]
        })
        return acc
      }, []))
    }
  }, [])

  useEffect(() => {
    if (localization?.path) {
      const items = $t<Record<string, string>>(localization.path) as Record<string, string>
      setFilterItems(
        Object.entries(items).reduce<TEnumFilterRow[]>((acc, [key, value]) => {
          acc.push({ key, title: value })
          return acc
        }, [])
        .sort((a, b) => {
          if (a.title.toLowerCase() < b.title.toLowerCase()) return -1
          if (a.title.toLowerCase() > b.title.toLowerCase()) return 1
          return 0
        })
      )
    }
  }, [localization?.path])

  return (
    <form
      css={inputElementFilterCSS}
      onSubmit={submitFormFilter}
    >
      <div style={{ position: 'relative', overflow: 'hidden' }}>
        <MultiSelect
          optionLabel="title"
          value={selectedItems}
          options={filterItems}
          placeholder={$t('_enumFilterPlaceholder')}
          onChange={(e) => applyFilter(e.value)}
        />
      </div>
      <Button
        type="submit"
        icon="pi pi-search"
        disabled={!selectedItems.length}
        className={!selectedItems.length ? 'p-button-outlined p-button-secondary' : ''}
      />
      <Button
        type="button"
        icon="mdi mdi-18px mdi-filter-off"
        onClick={resetFilter}
      />
    </form>
  )
}

const inputElementFilterCSS = css`
  display: grid;
  grid-template-columns: 1fr calc(40rem / var(--bfs)) calc(40rem / var(--bfs));
  gap: calc(10rem / var(--bfs));

  .p-button {
    border-radius: var(--border-radius);
    margin: 0;
    width: auto;
    height: auto;
    color: var(--text-color-active);
  }

  .p-multiselect {
    width: 100%;
    background: var(--input-bg-filled);

    .p-placeholder {
      text-align: left;
    }
  }
`
