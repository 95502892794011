/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useLocale } from '../../hooks/useLocale';
import { CloseIcon } from '../icons/CloseIcon';
import { TBasicFilterProps } from '../cells/TableHeadCell';
import { useObserver } from '../../hooks/useObserver';
import { useFilters } from '../../hooks/useFilters';
import { name } from '../../../package.json'

export const TextFilter = ({ columnKey, closeFilterPanel, sortingField }: TBasicFilterProps) => {
  const { $t } = useLocale()
  const { filters, setFilters } = useFilters()
  const { eventBus, eventBusID } = useObserver()
  const inputRef = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState('')
  const cleanInputField = () => {
    setValue('')
    inputRef.current?.focus()
  }

  const resetFilter = (e: SyntheticEvent) => {
    cleanInputField()
    submitFormFilter(e, '')
  }

  const submitFormFilter = (e: SyntheticEvent, emptyValue?: string) => {
    e.preventDefault()
    eventBusID && eventBus.notify(eventBusID, 'filterUpdate', {
      publisher: name,
      occurrenceTime: Date.now(),
      data: {
        payload: {
          [columnKey]: typeof emptyValue === 'string' ? emptyValue : value
        },
        sortingField
      }
    })

    setFilters((prev) => {
      const newFilters = new Map(prev?.entries() || [])
      typeof emptyValue === 'string'
        ? newFilters.delete(columnKey)
        : newFilters.set(columnKey, value)
      return newFilters
    })
    
    closeFilterPanel(e)
  }

  useEffect(() => {
    const filterValue = filters?.get(columnKey)
    setValue(filterValue ? String(filterValue) : '')
    inputRef.current?.focus()
  }, [inputRef])

  return (
    <form
      css={textFilterFormCSS}
      onSubmit={submitFormFilter}
    >
      <div style={{ position: 'relative' }}>
        <InputText
          ref={inputRef}
          css={inputCSS}
          value={value}
          placeholder={$t('_search')}
          data-cy={`table-filter-input`}
          onChange={(e) => setValue(e.target.value)}
        />
        {value.length > 0 &&
          <button
            data-cy={`table-filter-clear`}
            type="button"
            css={cleanButtonCSS}
            onClick={cleanInputField}
          >
            <CloseIcon />
          </button>
        }
      </div>
      <Button
        data-cy={`table-filter-submit`}
        type="submit"
        icon="pi pi-search"
        disabled={!value.length}
        className={!value.length ? 'p-button-outlined p-button-secondary' : ''}
      />
      <Button
        data-cy={`table-filter-reset`}
        type="button"
        icon="mdi mdi-18px mdi-filter-off"
        onClick={resetFilter}
      />
    </form>
  )
}

const textFilterFormCSS = css`
  display: grid;
  grid-template-columns: 1fr calc(40rem / var(--bfs)) calc(40rem / var(--bfs));
  gap: calc(10rem / var(--bfs));

  .p-button {
    border-radius: var(--border-radius);
    margin: 0;
    width: auto;
    height: auto;
    color: var(--text-color-active);
  }
`

const inputCSS = css`
  background: var(--input-bg-filled);
  width: 100%;
  padding-right: calc(40rem / var(--bfs));
`

const cleanButtonCSS = css`
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: calc(40rem / var(--bfs));
  height: calc(40rem / var(--bfs));
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
