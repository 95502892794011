import React from 'react';
import { useLocale } from '../../hooks/useLocale';
import { TTableCellProps } from '../../types';

export const TableCellDate = ({ cell, row }: TTableCellProps) => {
  const { locale } = useLocale()
  let value: number | string = Number(row[cell]) || row[cell]
  
  return (
    <>
      {Number(value) === 0
        ? '-'
        : new Date(value).toLocaleDateString(`${locale}-${locale.toUpperCase()}`, {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          })}
    </>
  )
}
