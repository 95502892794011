/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useMemo, useState } from 'react';
import { TDetailsCell, TTableConfigColumn, TTableRow, TableGroup } from '../types';
import { TableBodyCell } from './cells/TableBodyCell';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { useSchema } from '../hooks/useSchema';

interface ITableRowProps {
  isSelectable: boolean;
  isSwipable: boolean;
  row: TTableRow | TTableRow[];
  order: string[];
  selectedRows: string[];
  columns: Record<string, TTableConfigColumn>;
  detailsCell?: TDetailsCell;
  detailsOpen: (id: string) => void;
  selectUnselectRow: (id: string) => void;
}

export const TableRow = ({
  row,
  order,
  columns,
  isSwipable,
  detailsCell,
  detailsOpen,
  selectedRows,
  isSelectable,
  selectUnselectRow
}: ITableRowProps) => {
  const { schema } = useSchema()
  const [isCollapsed, setIsCollapsed] = useState(true)

  const activeGroups = useMemo(() => (
    schema.viewTable.groups?.reduce<Map<string, TableGroup>>((acc,next) => {
      if (next.isActive) {
        acc.set(next.field, next)
      }
      return acc
    }, new Map())
  ), [schema.viewTable.groups])

  const colSpan = useMemo(() => (
    order.length + Number(isSwipable || typeof schema.isGrouped !== 'undefined') || 0
  ), [order, isSwipable, schema.isGrouped])

  const passDetails = (rowID: string) => {
    detailsOpen(rowID)
  }

  return (
    Array.isArray(row)
      ? <tr>
          {
            <td colSpan={colSpan}>
              <table
                css={groupTableCSS}
                style={{
                  background: !isCollapsed ? 'var(--selection-gradient)' : 'transparent',
                  boxShadow: !isCollapsed ? `
                    inset calc(2rem / var(--bfs)) calc(2rem / var(--bfs)) 0px 0px rgba(var(--primary-color-raw), 0.8),
                    inset calc(-2rem / var(--bfs)) calc(-2rem / var(--bfs)) 0px 0px rgba(var(--primary-color-raw), 0.8)
                  ` : 'none'
                }}
              >
                <tbody>
                  {(isCollapsed ? [row[0]] : row).map((r, index) => (
                    <tr
                      key={`${r.id}.${index}`}
                      className="wtable__row --group"
                      css={tableRowCSS}
                    >
                      {isSelectable &&
                        <td
                          className="wtable__cell"
                          style={{
                            width: "var(--spacer-lg)",
                            maxWidth: "var(--spacer-lg)",
                            textAlign: "center"
                          }}
                        >
                          <Checkbox
                            onChange={() => selectUnselectRow(r.id)}
                            checked={selectedRows.includes(r.id)}
                          />
                        </td>
                      }
                      {
                        order.map((cellKey, i) => (
                          index === 0
                            ? <TableBodyCell
                                key={`${r.id}.${cellKey}.${index}${i}`}
                                cell={cellKey}
                                row={r}
                                isFirst={i === 0}
                                colSchema={columns[cellKey]}
                                detailsCell={detailsCell}
                                detailsOpen={() => passDetails(r.id)}
                              />
                            : activeGroups?.has(cellKey)
                              ? <td className="wtable__cell" key={`${r.id}.${cellKey}.${index}${i}`}></td>
                              : <TableBodyCell
                                  key={`${r.id}.${cellKey}.${index}${i}`}
                                  cell={cellKey}
                                  row={r}
                                  isFirst={i === 0}
                                  colSchema={columns[cellKey]}
                                  detailsCell={detailsCell}
                                  isInGroup={true}
                                  detailsOpen={() => passDetails(r.id)}
                                />
                        ))
                      }
                      <td
                        className="wtable__cell"
                        style={{
                          width: 'calc(30rem / var(--bfs) + 70px)',
                          maxWidth: 'calc(30rem / var(--bfs) + 70px)'
                        }}
                      >
                        {index === 0 && 
                          <Button
                            icon={isCollapsed ? 'mdi mdi-menu-right' : 'mdi mdi-menu-down'}
                            label={String(row.length)}
                            className="p-button-outlined p-button-secondary --toggler"
                            onClick={() => setIsCollapsed(!isCollapsed)}
                          />
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          }
        </tr>
      : <tr>
          <td colSpan={colSpan}>
            <table css={groupTableCSS}>
              <tbody>
                <tr className="wtable__row" css={tableRowCSS}>
                  {isSelectable &&
                    <td
                      className="wtable__cell"
                      style={{
                        width: "var(--spacer-lg)",
                        maxWidth: "var(--spacer-lg)",
                        textAlign: "center"
                      }}
                    >
                      <Checkbox
                        onChange={() => selectUnselectRow(row.id)}
                        checked={selectedRows.includes(row.id)}
                      />
                    </td>
                  }
                  {
                    order.map((cellKey, index) => (
                      <TableBodyCell
                        key={`${index}.${cellKey}`}
                        cell={cellKey}
                        row={row}
                        isFirst={index === 0}
                        colSchema={columns[cellKey]}
                        detailsCell={detailsCell}
                        detailsOpen={() => passDetails(row.id)}
                      />)
                    )
                  }
                  {(isSwipable || schema.isGrouped) &&
                    <td
                      className="wtable__cell"
                      style={{
                        width: 'calc(30rem / var(--bfs) + 70px)',
                        maxWidth: 'calc(30rem / var(--bfs) + 70px)'
                      }}
                    />
                  }
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
  )
}

const groupTableCSS = css`
  width: 100%;
  overflow: hidden;
  table-layout: fixed;
  border-collapse: collapse;
  border-radius: var(--border-radius-sm);
`

const tableRowCSS = css`
  &.--group {
    .--toggler {
      background: transparent;
      border: 0;
      white-space: nowrap;
      backdrop-filter: none;

      &:hover {
        background: transparent !important;
        border: 0 !important;
      }
    }
  }
`
