/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import { attachZoomHandler } from '@netvision/front-utils/lib/common/handleZoom';
import { TCellEntity } from '../../types';

interface ZoomableImageProps {
  src: string;
  cellEntity: TCellEntity;
}

interface ZoomState {
  offset: { x: number; y: number };
  scale: number;
}

export const ZoomableImage = ({ src, cellEntity }: ZoomableImageProps) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(1);

  useEffect(() => {
    const targetRef = cellEntity === 'video' ? videoRef : imageRef;

    if (containerRef.current && targetRef.current) {
      const disposer = attachZoomHandler(containerRef.current, [targetRef.current]);
      const updateState = (state: ZoomState) => {
        if (containerRef.current && targetRef.current) {
          const rect = containerRef.current.getBoundingClientRect();
          const imageRect = targetRef.current.getBoundingClientRect();
          const bounds = { x: rect.width, y: rect.height };
          const maxOffset = {
            x: Math.min(0, bounds.x - imageRect.width * state.scale),
            y: Math.min(0, bounds.y - imageRect.height * state.scale)
          };

          const newOffset = {
            x: Math.max(maxOffset.x, Math.min(0, state.offset.x)),
            y: Math.max(maxOffset.y, Math.min(0, state.offset.y))
          };

          setOffset(newOffset);
          setScale(state.scale);
        }
      };
      containerRef.current.addEventListener('zoomChange', (e: any) => updateState(e.detail));
      return () => disposer();
    }
    return undefined;
  }, []);

  return (
    <div ref={containerRef} css={zoomContainerCSS}>
      {cellEntity === 'video' ? (
        <video
          src={src}
          ref={videoRef}
          css={zoomImageCSS}
          style={{ transform: `translate(${offset.x}px, ${offset.y}px) scale(${scale})` }}
        />
      ) : (
        <img
          src={src}
          ref={imageRef}
          css={zoomImageCSS}
          style={{ transform: `translate(${offset.x}px, ${offset.y}px) scale(${scale})` }}
        />
      )}
    </div>
  );
};

const zoomContainerCSS = css`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
`;

const zoomImageCSS = css`
  max-width: 100%;
  max-height: 100%;
`;
