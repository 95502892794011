/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Fragment, useMemo } from 'react';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { TableHeadCell } from './cells/TableHeadCell';
import { TTableHeader } from '../types';
import { useSchema } from '../hooks/useSchema';

type TTableHeadProps = {
  isChecked: boolean;
  columns: TTableHeader[];
  isSelectable?: boolean;
  firstColIndex: number;
  lastColIndex: number;
  isRouterEnabled: boolean;
  isSwipable: boolean;
  updateFirstColIndex: (index: number) => void;
  selectUnselectAllRows: () => void;
}

export const TableHead = ({
  isChecked,
  columns,
  isSelectable,
  firstColIndex,
  lastColIndex,
  isSwipable,
  updateFirstColIndex,
  selectUnselectAllRows
}: TTableHeadProps) => {
  const { schema } = useSchema()

  const isPrevColDisabled = useMemo(() => (
    firstColIndex === 0
  ), [firstColIndex])

  const isNextColDisabled = useMemo(() => (
    lastColIndex - columns.length === firstColIndex
  ), [lastColIndex, firstColIndex])

  const filterCellAlignment = (index: number) => (
    index < columns.length - 2 ?
      css`left: 0 !important; right: auto !important` :
      css`right: 0 !important; left: auto !important`
  )

  return (
    <Fragment>
      <thead
        className="wtable__head"
        style={{ textAlign: 'left' }}
      >
        <tr className="wtable__row">
          {isSelectable &&
            <th
              className="wtable__cell"
              data-cy={`table-header-cell-select`}
              style={{
                width: "var(--spacer-lg)",
                maxWidth: "var(--spacer-lg)",
                textAlign: "center"
              }}
            >
              <Checkbox
                onChange={selectUnselectAllRows}
                checked={isChecked}
              />
            </th>
          }
          {columns.map((th, index) => (
            <TableHeadCell
              key={th.title}
              th={th}
              isFirst={index === 0 && !isSelectable}
              filterCellAlignment={filterCellAlignment(index)}
            />
          ))}
          {isSwipable
            ? <th
                className="wtable__cell"
                data-cy={`table-header-cell-swipe`}
                style={{
                  width: 'calc(30rem / var(--bfs) + 70px)',
                  maxWidth: 'calc(30rem / var(--bfs) + 70px)'
                }}
              >
                <div css={swipeSectionCSS}>
                  <Button
                    data-cy={`table-header-button-swipe-left`}
                    icon="mdi mdi-chevron-left"
                    disabled={isPrevColDisabled}
                    className={`p-button-rounded p-button-outlined p-button-sm ${isPrevColDisabled ? 'p-button-secondary' : ''}`}
                    onClick={() => updateFirstColIndex(firstColIndex - 1)}
                  />
                  <Button
                    data-cy={`table-header-button-swipe-right`}
                    icon="mdi mdi-chevron-right"
                    disabled={isNextColDisabled}
                    className={`p-button-rounded p-button-outlined p-button-sm ${isNextColDisabled ? 'p-button-secondary' : ''}`}
                    onClick={() => updateFirstColIndex(firstColIndex + 1)}
                  />
                </div>
              </th>
            : schema.isGrouped &&
                <th
                  className="wtable__cell"
                  data-cy={`table-header-cell-grouped`}
                  style={{
                    width: 'calc(30rem / var(--bfs) + 70px)',
                    maxWidth: 'calc(30rem / var(--bfs) + 70px)'
                  }}
                />
          }
        </tr>
      </thead>
    </Fragment>
  )
}

const swipeSectionCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .p-button.p-button-rounded {
    font-size: 1.5rem;
  }
`
