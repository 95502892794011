import React, { useMemo } from 'react';
import { TTableCellProps } from '../../types';
import { useLocale } from '../../hooks/useLocale';
import { useData } from '../../hooks/useData';

export const TableCellString = ({ cell, row, colSchema }: TTableCellProps) => {
  const { $t } = useLocale()
  const { dataMap } = useData()

  const iconClass = useMemo<string | undefined>(() => (
    colSchema.cell?.icon?.path
      ? dataMap.get(row.id)?.[colSchema.cell.icon.path]
      : undefined
  ), [colSchema, row, dataMap])

  return (
    <>
      {iconClass && <i className={`wtable__cell-icon mdi ${iconClass}`} />}
      <span>
        {colSchema.cell.localization
          ? $t(`${colSchema.cell.localization.path}.${row[cell]}`) || row[cell]
          : row[cell]
        }
      </span>
    </>
  )
}
