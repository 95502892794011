export const localesRU = {
  _selected: 'Выбрано',
  _delete: 'Удалить',
  _copy: 'Скопировать',
  _clean: 'Очистить',
  _reset: 'Сбросить',
  _yes: 'Да',
  _no: 'Нет',
  _cancel: 'Отмена',
  _empty: '',
  _delSection: {
    header: 'Удалить',
    message: 'Удалить сущности? ({param})',
    confirm: 'Удалить'
  },
  _createSection: {
    button: {
      label: 'Добавить'
    }
  },
  _sorting: {
    disabled: 'Без сортировки',
    asc: 'По возрастанию',
    desc: 'По убыванию'
  },
  _filter: 'Фильтр',
  _filterAbsent: 'Фильтр не определен',
  _search: 'Поиск',
  _enumFilterPlaceholder: 'Выберите пункты',
  _apply: 'Применить',
  _noResults: 'Данные отсутствуют',
  _dateTimeFilterModes: {
    single: 'Одиночный',
    multiple: 'Множественный',
    range: 'Диапазон'
  },
  _dateTimeLocale: {
    firstDayOfWeek: 1,
    dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    dayNamesShort: ['Вос', 'Пон', 'Вто', 'Сре', 'Чет', 'Пят', 'Суб'],
    dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    monthNames: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь'
    ],
    monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Нов', 'Дек'],
    toggleButtons: {
      month: 'Месяц',
      week: 'Неделя',
      dayBefore: 'Вчера',
      today: 'Сегодня'
    },
    tooltips: {
      mode: 'Режим'
    }
  }
};
