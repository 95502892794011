import React from 'react';
import { Button } from 'primereact/button';

export const DetailsButton = ({ detailsOpen }: { detailsOpen: () => void }) => (
  <Button
    data-cy={`table-details-button`}
    icon="mdi mdi-18px mdi-launch"
    className={'p-button-text p-button-secondary --details'}
    onClick={detailsOpen}
  />
)