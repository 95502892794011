/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { textShortener } from '../../utils/functions';
import { useLocale } from '../../hooks/useLocale';
import { TTableCellProps } from '../../types';

export const TableCellCopy = ({ row, cell, colSchema }: TTableCellProps) => {
  const ref = useRef<HTMLInputElement & HTMLTextAreaElement>(null)
  const { $t } = useLocale()
  const [showShort, setShowShort] = useState(true)

  useEffect(() => {
    setShowShort(
      'isShort' in colSchema ? Boolean(colSchema.isShort) : true
    )
  }, [colSchema.isShort])
  return (
    <Fragment>
      <div css={copyCellCSS}>
        {
          colSchema.tag === 'textarea'
            ? <textarea
                ref={ref}
                title={row[cell]}
                value={showShort && row[cell] ? textShortener(row[cell], [0, 4, -4]) : row[cell]}
                onFocus={() => setShowShort(false)}
                onBlur={() => setShowShort('isShort' in colSchema ? Boolean(colSchema.isShort) : true)}
                readOnly={true}
              />
            : <input
                ref={ref}
                title={row[cell]}
                value={showShort && row[cell] ? textShortener(row[cell], [0, 4, -4]) : row[cell]}
                onFocus={() => setShowShort(false)}
                onBlur={() => setShowShort('isShort' in colSchema ? Boolean(colSchema.isShort) : true)}
                readOnly={true}
              />
        }
        <Button
          className="p-button-text p-button-secondary"
          icon="mdi mdi-18px mdi-content-copy"
          title={$t('_copy')}
          onClick={() => {
            if (ref.current) {
              ref.current.value = row[cell]
              ref.current.select()
              document.execCommand('copy')
            }
          }}
        />
      </div>
    </Fragment>
  )
}

const copyCellCSS = css`
  display: flex;
  align-items: center;

  input,
  textarea {
    font-family: monospace, monospace;
    width: calc(110rem / var(--bfs));
    margin-left: calc(-11rem / var(--bfs));
    padding: calc(5rem / var(--bfs)) calc(10rem / var(--bfs));
    color: var(--text-color-secondary);
    margin-right: var(--spacer-xs);
    border: calc(1rem/ var(--bfs)) solid transparent;
    background: transparent;
  }

  textarea {
    width: 100%;
    resize: none;
  }

  .p-button-text {
    opacity: 0;
    transition: opacity var(--transition-duration) ease;
  }

  &:hover {
    .p-button-text {
      opacity: 1;
    }
  }
`
