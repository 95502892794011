export class TypeChecker {
  static isString<T extends string>(value: unknown): value is T {
    return typeof value === 'string';
  }

  static isNumber<T extends number>(value: unknown): value is T {
    return typeof value === 'number' && !isNaN(value);
  }

  static isBoolean<T extends boolean>(value: unknown): value is T {
    return typeof value === 'boolean';
  }

  static isNull(value: unknown): value is null {
    return value === null;
  }

  static isUndefined(value: unknown) {
    return typeof value === 'undefined';
  }

  static isPrimitive(value: unknown) {
    return (
      TypeChecker.isString(value) ||
      TypeChecker.isNumber(value) ||
      TypeChecker.isBoolean(value) ||
      TypeChecker.isNull(value) ||
      TypeChecker.isUndefined(value)
    );
  }

  static isDate(value: any) {
    if (value instanceof Date) return true
    if (!isNaN(Number(value))) return true
    try {
      return !isNaN(Date.parse(new Date(value).toISOString()))
    } catch {
      return false
    }
  }

  static isIcon(value: unknown) {
    return (
      typeof value === 'object' &&
      !TypeChecker.isNull(value) &&
      'color' in value &&
      'iconClass' in value
    );
  }

  static isEnum(value: unknown) {
    return Array.isArray(value) && value.every((item) => TypeChecker.isPrimitive(item));
  }

  static isRegularURL(value: unknown) {
    const pattern =
      /((^(http|https):\/\/)|(^www+\.))(?!blob:)([a-z0-9-]+((\.[a-z0-9-]+)+)?)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return pattern.test(decodeURI(value as string));
  }

  static isShortURL(value: unknown) {
    const pattern =
      /(http|https):\/\/([a-z0-9-]+((\.[a-z0-9-]+)+)?)(:[0-9]+)?(\/([a-zA-Z0-9]{1,4}))?(\/([a-zA-Z0-9]{5,32}))$/;
    return pattern.test(decodeURI(value as string));
  }

  static isURL(value: unknown) {
    return (
      TypeChecker.isString(value) &&
      (TypeChecker.isRegularURL(value) || TypeChecker.isShortURL(value))
    );
  }

  static isBlobUrl(value: unknown) {
    if (!TypeChecker.isString(value) || !value.startsWith('blob:')) {
      return false;
    }
    const pureUrl = value.split('blob:')[1];
    return TypeChecker.isRegularURL(pureUrl) || TypeChecker.isShortURL(pureUrl);
  }
}