/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useMemo } from 'react';
import { TTableCellProps } from '../../types';
import { TableCellWrapper } from './TableCellWrapper';
import { TableCellIcon } from './TableCellIcon';
import { TableCellBoolean } from './TableCellBoolean';
import { TableCellDate } from './TableCellDate';
import { TableCellNumber } from './TableCellNumber';
import { TableCellPreview } from './TableCellPreview';
import { TableCellURL } from './TableCellURL';
import { TableCellCopy } from './TableCellCopy';
import { TableCellMount } from './TableCellMount';
import { TableCellString } from './TableCellString';
import { TableCellHTML } from './TableCellHTML';
import { TableCellCompare } from './TableCellCompare';
import { TableCellJSON } from './TableCellJSON';
import { TypeChecker } from '../../utils/TypesChecker';

type TableBodyCellExtProps = {
  isFirst: boolean;
  isInGroup?: boolean;
  detailsOpen: () => void;
}

export const TableBodyCell = ({
  cell,
  row,
  colSchema,
  isFirst,
  isInGroup,
  detailsCell,
  detailsOpen
}: TTableCellProps & TableBodyCellExtProps) => {
  const { isBoolean, isDate, isIcon, isNumber, isURL } = TypeChecker

  const CellComponentChildren = useMemo(() => (
    new Map([
      [
        'text',
        <TableCellString
          cell={cell}
          row={row}
          colSchema={colSchema}
        />
      ],
      [
        'icon',
        isIcon(row[cell])
          ? <TableCellIcon
              cell={cell}
              row={row}
            />
          : <TableCellString
              cell={cell}
              row={row}
              colSchema={colSchema}
            />
      ],
      [
        'date',
        isDate(row[cell])
          ? <TableCellDate
              cell={cell}
              row={row}
              colSchema={colSchema}
            />
          : <TableCellString
              cell={cell}
              row={row}
              colSchema={colSchema}
            />
      ],
      [
        'number',
        isNumber(row[cell])
          ? <TableCellNumber
              cell={cell}
              row={row}
              colSchema={colSchema}
            />
          : <TableCellString
              cell={cell}
              row={row}
              colSchema={colSchema}
            />
      ],
      [
        'boolean',
        isBoolean(row[cell])
          ? <TableCellBoolean
              cell={cell}
              row={row}
            />
          : <TableCellString
              cell={cell}
              row={row}
              colSchema={colSchema}
            />
      ],
      [
        'url',
        isURL(row[cell])
          ? <TableCellURL
              cell={cell}
              row={row}
            />
          : <TableCellString
              cell={cell}
              row={row}
              colSchema={colSchema}
            />
      ],
      [
        'src',
        <TableCellPreview
          cell={cell}
          row={row}
          colSchema={colSchema}
        />
      ],
      [
        'id',
        <TableCellCopy
          cell={cell}
          row={row}
          colSchema={colSchema}
        />
      ],
      [
        'copy',
        <TableCellCopy
          cell={cell}
          row={row}
          colSchema={colSchema}
        />
      ],
      [
        'widget',
        <TableCellMount
          cell={cell}
          row={row}
          colSchema={colSchema}
        />
      ],
      [
        'html',
        <TableCellHTML
          cell={cell}
          row={row}
        />
      ],
      [
        'compare',
        typeof row[cell] === 'string'
          ? <TableCellJSON cell={cell} row={row} colSchema={colSchema} />
          : <TableCellCompare comparison={row[cell]} />
      ]
    ])
  ), [colSchema, cell, row])
  
  const CellComponent = useMemo(() => {
    return (
      <TableCellWrapper
        isFirst={isFirst}
        styles={['widget', 'src'].includes(colSchema.cell.type) ? noPaddingsCell : css``}
        isDetailed={detailsCell?.cellKey === cell}
        detailsOpen={detailsOpen}
        isInGroup={isInGroup}
        cellKey={cell}
      >
        {CellComponentChildren.get(colSchema.cell.type) || <span>{colSchema.cell.type}</span>}
      </TableCellWrapper>
    )
  }, [colSchema, cell, row])

  return CellComponent
}

const noPaddingsCell = css`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`
