/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, SerializedStyles } from '@emotion/react';
import { ReactNode } from 'react';
import { DetailsButton } from '../shared/DetailsButton';

type TableCellWrapperProps = {
  children: ReactNode
  isFirst: boolean
  isInGroup?: boolean
  styles?: SerializedStyles
  cellKey?: string
  isDetailed: boolean
  detailsOpen: () => void
}

export const TableCellWrapper = ({
  isFirst,
  children,
  styles,
  isInGroup,
  isDetailed,
  cellKey="",
  detailsOpen
}: TableCellWrapperProps) => {
  return (
    <td
      data-cy={`table-cell-${cellKey}`} 
      className={`wtable__cell ${isInGroup ? '--grouped' : ''}`}
      css={[styles, cellCSS]}
      style={isFirst ? { paddingLeft: 'calc(30rem / var(--bfs))' } : {}}
    >
      <div css={cellContentCSS}>
        {children}
        {isDetailed && <DetailsButton detailsOpen={detailsOpen} />}
      </div>
    </td>
  )
}

const cellCSS = css`
  &.--grouped {
    border-top: 1px solid var(--text-color-secondary);
  }

  .wtable__cell-icon {
    color: var(--warning-color);
    height: calc(24rem / var(--bfs));
    width: calc(24rem / var(--bfs));
    font-size: calc(24rem / var(--bfs));
    margin-right: calc(15rem / var(--bfs));
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const cellContentCSS = css`
  display: flex;
  align-items: center;

  .--details {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    .--details {
      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }
`
