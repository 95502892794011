import React from 'react';
import { TTableCellNumberProps } from '../../types';
import { Formatter } from '../../utils/Formatter';

export const TableCellNumber = ({ row, cell, colSchema }: TTableCellNumberProps) => {
  return (
    <div style={{ textAlign: 'right', flex: 1 }}>
      {
        colSchema.cell.format
          ? Formatter.format(row[cell], colSchema.cell.format)
          : row[cell]
      }
    </div>
  )
}
