import React, { useMemo } from 'react';
import { Comparison } from '../../types';
import { useLocale } from '../../hooks/useLocale';

type TableCellCompare = {
  comparison: Comparison
}

export const TableCellCompare = ({ comparison }: TableCellCompare) => {
  const { $t } = useLocale()

  const ContentTable = useMemo(() => {
    return !Reflect.ownKeys(comparison.secondStateData).length
      ? <span>{$t('_empty')}</span>
      : <span>{Object.values(comparison.secondStateData).map(({ key }) => key).join(', ')}</span>
  }, [comparison])

  return ContentTable
}
