/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, SerializedStyles } from '@emotion/react'
import { useMemo, useRef, useState } from 'react'
import { TDateTimeProps, TTableConfigColumn, TTableHeader } from '../../types'
import { useLocale } from '../../hooks/useLocale'
import { useFilters } from '../../hooks/useFilters'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { OverlayPanel, OverlayPanelEventType } from 'primereact/overlaypanel'
import { TextFilter } from '../filters/TextFilter'
import { FilterIcon } from '../icons/FilterIcon'
import { CloseIcon } from '../icons/CloseIcon'
import { AutocompleteRefFilter } from '../filters/AutocompleteRefFilter'
import { DateTimeFilter } from '../filters/DateTimeFilter'
import { NumberFilter } from '../filters/NumberFilter'
import { RangeFilter } from '../filters/RangeFilter'
import { EnumFilter } from '../filters/EnumFilter'

type TTableHeadCellProps = {
  th: TTableHeader
  isFirst: boolean
  filterCellAlignment: SerializedStyles
}

export type TBasicFilterProps = {
  columnKey: string
  sortingField: unknown
  closeFilterPanel: (e: OverlayPanelEventType) => void
}

export type TDateTimeFilterProps = Omit<TBasicFilterProps, 'closeFilterPanel'> & {
  closeFilterPanel: () => void
  dateTimeProps?: TDateTimeProps
}

export type TEnumFilter = TBasicFilterProps & {
  localization?: TTableConfigColumn['cell']['localization']
}

export const TableHeadCell = ({
  th,
  isFirst,
  filterCellAlignment
}: TTableHeadCellProps) => {
  const { $t } = useLocale()
  const { filters, sorting, setSorting } = useFilters()
  const filterRef = useRef<OverlayPanel>(null)
  const [dialogModalFilters] = useState<string[]>([])
  const [isFilterActive, setFilterState] = useState(false)
  const [tableFilters] = useState(new Map([
    [
      'text',
      <TextFilter
        columnKey={th.columnKey}
        sortingField={th.schema.filter?.field}
        closeFilterPanel={(e: OverlayPanelEventType) => toggleFilterPanel(e)}
      />
    ],
    [
      'range',
      <RangeFilter
        columnKey={th.columnKey}
        sortingField={th.schema.filter?.field}
        closeFilterPanel={(e: OverlayPanelEventType) => toggleFilterPanel(e)}
      />
    ],
    ['autocompleteRef', <AutocompleteRefFilter />],
    [
      'dateTime',
      <DateTimeFilter
        columnKey={th.columnKey}
        sortingField={th.schema.filter?.field}
        dateTimeProps={{ max: Date.now() }}
        closeFilterPanel={() => {
          setFilterState(false)
          filterRef.current?.hide()
        }}
      />
    ],
    ['number', <NumberFilter />],
    [
      'enum',
      <EnumFilter
        columnKey={th.columnKey}
        sortingField={th.schema.filter?.field}
        localization={th.schema.cell.localization}
        closeFilterPanel={(e: OverlayPanelEventType) => toggleFilterPanel(e)}
      />
    ]
  ]))

  const [cellCSS] = useState(new Map([
    ['href', previewCellCSS],
    ['number', rightAligned],
    ['boolean', centerAligned]
  ]))

  const sortRows = (field: string) => {
    const newSorting = new Map()
    if (sorting?.has(field)) {
      const currentValue = sorting.get(field)
      switch(currentValue) {
        case 0:
          newSorting.set(field, 1)
          break
        case 1:
          newSorting.set(field, -1)
          break
      }
    } else {
      newSorting.set(field, 1)
    }
    setSorting(newSorting)
  }

  const toggleFilterPanel = (e: OverlayPanelEventType) => {
    filterRef.current?.toggle(e)
    setFilterState(!isFilterActive)
  }

  const iconSortingClass = useMemo(() => {
    if (!sorting || !sorting.size) return 'pi-sort-alt'
    const currentSorting = sorting.get(String(th.schema.sorting?.field))
    if (!currentSorting) return 'pi-sort-alt'
    return (
      currentSorting === -1
        ? 'pi-sort-amount-down-alt'
        : 'pi-sort-amount-up'
    )
  }, [sorting])

  const tooltipSortingLabel = useMemo(() => {
    if (!sorting || !sorting.size) return $t<string>('_sorting.disabled')
    const currentSorting = sorting.get(String(th.schema.sorting?.field))
    if (!currentSorting) return $t<string>('_sorting.disabled')
    return (
      currentSorting === -1
        ? $t<string>('_sorting.desc')
        : $t<string>('_sorting.asc')
    )
  }, [sorting])

  const CurrentFilterIcon = useMemo(() => (
    !isFilterActive ? <FilterIcon /> : <CloseIcon />
  ), [isFilterActive])

  const FilterComponent = useMemo(() => (
    th.schema?.filter?.type
      ? tableFilters.get(th.schema?.filter?.type)
      : <div>{$t('_filterAbsent')}</div>
  ), [th.schema?.filter?.type])
  
  const isFiltered = useMemo(() => {
    if (!filters) return false
    const parsedKey = [...filters.keys()].find((key) => key.startsWith(th.columnKey))
    return parsedKey ? filters.has(parsedKey) : false
  }, [filters])

  return (
    <th
      css={cellCSS.get(th.schema.cell.type)}
      className="wtable__cell"
    >
      <div
        css={thCellCSS}
        style={isFirst ? { paddingLeft: 'calc(30rem / var(--bfs))' } : {}}
      >
        <span 
          data-cy={`table-header-tittle-${th.columnKey}`}
          css={css`color: var(--text-color)`}>
            {th.title}
        </span>
        {typeof th.schema.filter !== 'undefined' &&
          <Button
            data-cy={`table-header-button-filter-${th.columnKey}`}
            className={`p-button-text wtable__cell-button ${isFiltered ? '--badged' : ''}`}
            icon={CurrentFilterIcon}
            title={$t('_filter')}
            onClick={toggleFilterPanel}
          />
        }
        {typeof th.schema.sorting !== 'undefined' &&
          <Button
            data-cy={`table-header-button-sort-${th.columnKey}`}
            className="p-button-text wtable__cell-button"
            icon={`pi ${iconSortingClass}`}
            title={tooltipSortingLabel}
            onClick={() => sortRows(String(th.schema.sorting?.field))}
          />
        }
        {th.schema?.filter?.type && !dialogModalFilters.includes(th.schema?.filter?.type)
          ? <OverlayPanel
              ref={filterRef}
              css={filterCellAlignment}
              appendTo="self"
              onHide={() => setFilterState(false)}
            >{FilterComponent}</OverlayPanel>
          : <Dialog
              headerStyle={{ display: 'none' }}
              closable={false}
              visible={isFilterActive}
              onHide={() => setFilterState(false)}
            >{FilterComponent}</Dialog>
        }
      </div>
    </th>
  )
}

const previewCellCSS = css`
  width: calc(155rem / var(--bfs));
  max-width: calc(155rem / var(--bfs));
`

const rightAligned = css`
  & > div {
    justify-content: flex-end;
  }
`

const centerAligned = css`
  & > div {
    justify-content: center;
  }
`

const thCellCSS = css`
  display: flex;
  align-items: center;
  position: relative;

  & > span {
    margin-right: calc(10rem / var(--bfs));
  }

  .wtable__cell-button {
    color: var(--text-color-secondary);
    margin-left: calc(10rem / var(--bfs));
    width: calc(20rem / var(--bfs));
    height: calc(20rem / var(--bfs));
    padding: 0;

    &:hover {
      color: var(--text-color) !important;
    }

    &.--badged {
      position: relative;
      overflow: initial;

      &:before {
        content: '';
        position: absolute;
        right: 0px;
        top: 0px;
        transform: translate(50%, -50%);
        border-radius: 50%;
        height: calc(10rem / var(--bfs));
        width: calc(10rem / var(--bfs));
        background: var(--primary-color);
      }
    }
  }

  .p-overlaypanel {
    background: var(--surface-b);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-raised);
    top: 100% !important;
    width: calc(430rem / var(--bfs));
    padding: var(--spacer-xs);

    &-content {
      padding: 1rem !important;
    }
  }
`
