/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { TableCellMount } from './TableCellMount';
import { TTableCellProps } from '../../types';
import { TypeChecker } from '../../utils/TypesChecker';
import { ZoomableImage } from '../shared/ZoomableImage'
import { EmptyIcon } from '../icons/EmptyIcon';
import { ColorBuilder } from '../../utils/ColorBuilder'


export const TableCellPreview = ({ cell, row, colSchema }: TTableCellProps) => {
  const { isURL, isBlobUrl } = TypeChecker;
  const cellRef = useRef<HTMLTableCellElement>(null);
  const imageElement = useRef<HTMLImageElement>(null);
  const videoElement = useRef<HTMLVideoElement>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const errorColor = ColorBuilder.rgbToHex(ColorBuilder.getColorValues('--error-color-raw'));

  const PreviewPlaceholderLoading = () => (
    <div css={loadingPreview}>
      <ProgressSpinner style={{ width: 'var(--spacer)', height: 'var(--spacer)' }} />
    </div>
  )

  return (
    <div css={previewCellCSS} ref={cellRef}>
      {typeof row[cell] === 'undefined'
        ? <PreviewPlaceholderLoading />
        : isURL(row[cell]) || isBlobUrl(row[cell])
          ? <div
            css={previewWrapperCSS}
            onClick={() => setIsPreviewOpen(true)}
            className={isPreviewOpen ? 'opened' : ''}
          >
            {colSchema.cell.entity === 'video'
              ? <video src={row[cell]} css={previewImageCSS} ref={videoElement} />
              : <img src={row[cell]} css={previewImageCSS} ref={imageElement} />
            }
            <i className="mdi mdi-24px mdi-magnify-plus-outline" />
          </div>
          : <EmptyIcon color={errorColor} width='100' height='64' viewBox='70 150 400 113' opacity='0.4' />
      }
      <Dialog
        header={row.title || row.name || ''}
        visible={isPreviewOpen}
        dismissableMask={true}
        css={modalPreview}
        onHide={() => setIsPreviewOpen(false)}
      >
        {isURL(row[cell]) || isBlobUrl(row[cell])
          ? <ZoomableImage src={row[cell]} cellEntity={colSchema.cell.entity || 'image'} />
          : <TableCellMount cell={cell} row={row} colSchema={colSchema} />
        }
      </Dialog>
    </div>
  )
}

const previewCellCSS = css`
  width: calc(100rem / var(--bfs));
  max-width: calc(100rem / var(--bfs));
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  text-align: center;
  flex: 1;
`

const previewWrapperCSS = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.opened {
    &:before {
      opacity: 1;
    }

    .mdi-magnify-plus-outline {
      opacity: 1;
      color: var(--text-color);
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity var(--transition-duration) ease;
  }

  .mdi-magnify-plus-outline {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    opacity: 0;
    transition: opacity var(--transition-duration) ease;
  }

  &:hover {
    &:before {
      opacity: 1;
    }

    .mdi-magnify-plus-outline {
      opacity: 1;
    }
  }
`

const previewImageCSS = css`
  width: 100%;
  height: calc(64rem / var(--bfs));
  object-fit: cover;
  display: block;
  border-radius: calc(var(--border-radius) / 3);
`

const modalPreview = css`
  width: 50%;
  min-height: 15rem;
  overflow: hidden;

  .media-preview {
    width: 100%;
    display: block;
  }
`

const loadingPreview = css`
  height: calc(64rem / var(--bfs));
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(var(--secondary-color-alt-2-raw), 0.5);
  border-radius: var(--border-radius);
`
